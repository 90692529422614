<template>
	<div class="app-container">
		<el-card class="box-card campaign-add-card">
			<div slot="header" class="clearfix">
				<span>{{$t('sells.campaign.header-1')}}</span>
			</div>
			<el-form :model="campaign" :rules="rules" ref="campaign">
				<div>
					<div class="sub-title">{{$t('sells.campaign.campaign-name')}}</div>
					<el-form-item prop="name">
						<el-input type="text" size="small" v-model="campaign.name"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.campaign-desc')}}</div>
					<el-form-item prop="description">
						<el-input type="textarea" size="small" v-model="campaign.description"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.discount-type')}}</div>
					<el-form-item prop="discountType">
						<el-select size="small" v-model="campaign.discountType" class="fullWidth"
							:placeholder="$t('sells.campaign.pick-discount')">
							<el-option v-for="item in discountType" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.coupon-value')}} ({{valueTipe}})</div>
					<el-form-item prop="value">
						<el-input-number class="fullWidth" size="small" :min="1" type="text" v-model="campaign.value">
						</el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.min-order')}} (RON)</div>
					<el-form-item prop="minOrder">
						<el-input-number class="fullWidth" size="small" :min="1" type="text"
							v-model="campaign.minOrder"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.use-total')}}</div>
					<el-form-item prop="totalUtilize">
						<el-input-number class="fullWidth" size="small" :min="1" type="text"
							v-model="campaign.totalUtilize"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.use-user')}}</div>
					<el-form-item prop="userUtilize">
						<el-input-number class="fullWidth" size="small" :min="1" type="text"
							v-model="campaign.userUtilize"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.date-start')}}</div>
					<el-form-item prop="dateStart">
						<el-date-picker size="small" class="fullWidth" v-model="campaign.dateStart"
							value-format="yyyy-MM-dd" type="date">
						</el-date-picker>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.date-end')}}</div>
					<el-form-item prop="dateEnd">
						<el-date-picker size="small" class="fullWidth" v-model="campaign.dateEnd"
							value-format="yyyy-MM-dd" type="date">
						</el-date-picker>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.domain-res')}}</div>
					<el-form-item prop="domainRestriction">
						<el-input size="small" type="text" v-model="campaign.domainRestriction"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.product-res')}}</div>
					<el-form-item prop="productRestrictions">
						<el-checkbox-group v-model="campaign.productRestrictions" size="small">
							<el-checkbox-button v-for="p in productType" :label="p.value" :key="p.label">{{p.label}}
							</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.campaign.campaign-status')}}</div>
					<el-form-item prop="campaignStatus">
						<el-switch v-model="campaign.active" :active-text="$t('sells.campaign.active')"
							:inactive-text="$t('sells.campaign.inactive')">
						</el-switch>
					</el-form-item>
				</div>
				<el-button type="success" class="fullWidth" size="small" icon="el-icon-check" @click="saveCampaign">
					{{$t('sells.campaign.save')}}</el-button>
			</el-form>
		</el-card>
		<div class="separator"></div>
		<el-row :gutter="12">
			<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
				<el-card class="box-card" body-style="min-height: 300px">
					<div slot="header" class="clearfix">
						<span>{{$t('sells.campaign.category-res')}}
							<el-button style="float:right" type="primary" size="mini" icon="el-icon-plus"
								@click="addRestriction('category')">{{$t('sells.campaign.add-res')}}</el-button>
						</span>
					</div>
					<div class="restrictions-block">
						<div class="restriction-category" v-for="(res, index) in restrictions.category" :key="index">
							<div class="sub-title">{{$t('sells.campaign.res')}} {{index + 1}}</div>
							<span class="select-1">
								<el-cascader size="small" class="width-80" change-on-select filterable
									expand-trigger="click" clearable :placeholder="$t('sells.campaign.set-category')"
									:options="categories" v-model="res.category_id">
								</el-cascader>
							</span>
							<span class="select-2">
								<el-select size="small" class="width-17" v-model="res.coupon_restrict"
									:placeholder="$t('sells.campaign.set-res-type')">
									<el-option v-for="item in restrictType" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</span>
							<el-button type="danger" class="delete-btn" icon="el-icon-delete" size="small" circle
								@click="deleteRestriction('category', index)"></el-button>
						</div>
					</div>
					<div class="separator"></div>
					<el-button type="success" class="fullWidth" size="small" icon="el-icon-check"
						@click="saveRestriction('category')">{{$t('sells.campaign.save-cat-res')}}</el-button>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
				<el-card class="box-card" body-style="min-height: 300px">
					<div slot="header" class="clearfix" style="position:relative">
						<span>{{$t('sells.campaign.man-res')}}
							<el-button style="float:right" type="primary" size="mini" icon="el-icon-plus"
								@click="addRestriction('manufacturer')">{{$t('sells.campaign.add-res')}}</el-button>
						</span>
					</div>
					<div class="restrictions-block">
						<div class="restriction-manufacturer" v-for="(res, index) in restrictions.manufacturer"
							:key="index">
							<div class="sub-title">{{$t('sells.campaign.res')}} {{index + 1}}</div>
							<span class="select-1">
								<el-select size="small" class="width-80" v-model="res.manufacturers_id" filterable
									:placeholder="$t('sells.campaign.set-man')">
									<el-option v-for="item in manufacturers" :key="item.manufacturers_id"
										:label="item.manufacturers_name" :value="item.manufacturers_id">
									</el-option>
								</el-select>
							</span>
							<span class="select-2">
								<el-select size="small" class="width-17" v-model="res.coupon_restrict"
									:placeholder="$t('sells.campaign.set-res-type')">
									<el-option v-for="item in restrictType" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</span>
							<el-button type="danger" class="delete-btn" icon="el-icon-delete" size="small" circle
								@click="deleteRestriction('manufacturer', index)"></el-button>
						</div>
					</div>

					<div class="separator"></div>
					<el-button type="success" class="fullWidth" size="small" icon="el-icon-check"
						@click="saveRestriction('manufacturer')">{{$t('sells.campaign.save-man-res')}}</el-button>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
				<el-card class="box-card" body-style="min-height: 300px">
					<div slot="header" class="clearfix">
						<span>{{$t('sells.campaign.product-res-2')}}
							<el-button style="float:right" type="primary" size="mini" icon="el-icon-plus"
								@click="addRestriction('product')">{{$t('sells.campaign.add-res')}}</el-button>
						</span>
					</div>
					<div class="restrictions-block">
						<div class="restriction-manufacturer" v-for="(res, index) in restrictions.product" :key="index">
							<div class="sub-title">{{$t('sells.campaign.res')}} {{index + 1}}</div>
							<span class="select-1">
								<el-select size="small" class="width-80" v-model="res.model" filterable
									:placeholder="$t('sells.campaign.set-product')">
									<el-option v-for="item in products" :key="item.products_model"
										:label="item.products_name" :value="item.products_model">
									</el-option>
								</el-select>
							</span>
							<span class="select-2">
								<el-select size="small" class="width-17" v-model="res.coupon_restrict"
									:placeholder="$t('sells.campaign.set-res-type')">
									<el-option v-for="item in restrictType" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</span>
							<el-button type="danger" class="delete-btn" icon="el-icon-delete" size="small" circle
								@click="deleteRestriction('product', index)"></el-button>
						</div>
					</div>

					<div class="separator"></div>
					<el-button type="success" class="fullWidth" size="small" icon="el-icon-check"
						@click="saveRestriction('product')">{{$t('sells.campaign.save-product-res')}}</el-button>
				</el-card>
			</el-col>
		</el-row>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('sells.campaign.coupons')}}</span>
			</div>
			<div class="title">{{$t('sells.campaign.generate-coupons')}}
			</div>
			<el-input-number size="small" v-model="couponsToGenerate" :min="1" :max="30"></el-input-number>&nbsp;&nbsp;
			<el-button type="primary" size="small" @click="generateCoupons">{{$t('sells.campaign.generate')}}
			</el-button>
			<div class="separator"></div>

			<el-table @cell-click="checkCustomClick" class="coupons-table" ref="couponsTable" :data="coupons"
				size="small" :border="true" highlight-current-row style="width: 100%">
				<el-table-column type="index" width="50">
				</el-table-column>
				<el-table-column property="coupon_code" :label="$t('sells.campaign.code')">
				</el-table-column>
				<el-table-column class-name="custom-cell" align="center" property="custom_amount"
					:label="$t('sells.campaign.custom-value')" width="150">
					<template slot-scope="scope">
						{{scope.row.custom_amount === null ? $t('general.no') : scope.row.custom_amount}}
					</template>
				</el-table-column>
				<el-table-column class-name="custom-cell" align="center" property="custom_expire"
					:label="$t('sells.campaign.custom-expire')" width="110">
					<template slot-scope="scope">
						{{scope.row.custom_expire !== '0000-00-00' && scope.row.custom_expire != null  ? scope.row.custom_expire : $t('general.no')}}
					</template>
				</el-table-column>
				<el-table-column align="center" property="uses" :label="$t('sells.campaign.users')" width="80">
				</el-table-column>
				<el-table-column align="center" property="added" :label="$t('sells.campaign.generated')" width="150">
				</el-table-column>
				<el-table-column align="center" property="status" label="Status" width="70">
					<template slot-scope="scope">
						<el-button @click="toggleCouponStatus(scope.row)" size="mini"
							:type="parseInt(scope.row.status) === 1 ? 'success' : 'danger'"
							:icon="parseInt(scope.row.status) === 1 ? 'el-icon-check' : 'el-icon-close'" circle>
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<el-dialog :title="$t('sells.campaign.coupon-value') + ' ' + customAmount.couponCode"
			:visible.sync="customAmount.modal" width="30%">
			<el-input-number class="fullWidth" size="small" v-model="customAmount.amount" :min="1"></el-input-number>

			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="danger" @click="deleteCustomAmount" style="float: left">
					{{$t('sells.campaign.delete-custom-value')}}</el-button>
				<el-button size="small" type="info" @click="resetCustomAmountModal">{{$t('general.cancel')}}</el-button>
				<el-button size="small" type="primary" @click="saveCustomAmount">{{$t('general.save')}}</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="$t('sells.campaign.expire-date') + ' ' + customExpire.couponCode"
			:visible.sync="customExpire.modal" width="30%">
			<el-date-picker value-format="yyyy-MM-dd" class="fullWidth" v-model="customExpire.date" type="date"
				:placeholder="$t('sells.campaign.pick-date')">
			</el-date-picker>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="danger" @click="deleteCustomExpire" style="float: left">
					{{$t('sells.campaign.delete-date')}}</el-button>
				<el-button size="small" type="info" @click="resetCustomExpireModal">{{$t('general.cancel')}}</el-button>
				<el-button size="small" type="primary" @click="saveCustomExpire">{{$t('general.save')}}</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import {
		getCouponsCampaignData,
		getSupplierCategsBrandsAndProducts,
		updateCouponsCampaign,
		updateCampaignRestrictions,
		deleteCampaignRestriction,
		toggleCouponStatus,
		saveCustomAmount,
		deleteCustomAmount,
		saveCustomExpire,
		deleteCustomExpire,
		generateCoupons
	} from '@/api/vanzari';
	import {
		debugPromise,
		parseCategories
	} from '@/utils/index';
	import {
		EventBus
	} from '@/utils/Bus.js';
	import has from 'lodash.has';
	import union from 'lodash.union';
	import forEach from 'lodash.foreach';
	export default {
		props: ['cid'],
		data() {
			return {
				cardOpened: false,
				campaign: {
					name: '',
					description: '',
					discountType: '',
					value: '',
					minOrder: '',
					totalUtilize: '',
					userUtilize: '',
					dateStart: '',
					dateEnd: '',
					domainRestriction: '',
					productRestrictions: [],
				},
				restrictions: {
					category: [{
						restrict_id: 0,
						category_id: [],
						coupon_restrict: 'N'
					}],
					manufacturer: [{
						restrict_id: 0,
						manufacturers_id: '',
						coupon_restrict: 'N'
					}],
					product: [{
						restrict_id: 0,
						products_model: '',
						coupon_restrict: 'N'
					}]
				},
				customAmount: {
					modal: false,
					amount: 0,
					couponId: 0,
					couponCode: ''
				},
				customExpire: {
					modal: false,
					date: '',
					couponId: 0,
					couponCode: ''
				},
				couponsToGenerate: 0,
				categories: [],
				manufacturers: [],
				products: [],
				campaignId: 0,
				coupons: [],
				restrictType: [{
						value: 'N',
						label: this.$t('sells.campaign.restrict-type-n')
					},
					{
						value: 'Y',
						label: this.$t('sells.campaign.restrict-type-y')
					}
				],
				discountType: [{
						label: this.$t('sells.campaign.discount-type-f'),
						value: 'F',
						currency: 'RON'
					},
					{
						label: this.$t('sells.campaign.discount-type-p'),
						value: 'P',
						currency: '%'
					},
					// {
					// 	label: 'Shipping',
					// 	value: 'S'
					// }
				],
				productType: [{
						label: this.$t('sells.campaign.product-type-normal'),
						value: 0
					},
					{
						label: this.$t('sells.campaign.product-type-promotion'),
						value: 1
					},
					{
						label: this.$t('sells.campaign.product-type-lichid'),
						value: 2
					},
					{
						label: this.$t('sells.campaign.product-type-resig'),
						value: 3
					},
					{
						label: this.$t('sells.campaign.product-type-refurb'),
						value: 4
					}
				],
				rules: {
					description: [{
						min: 5,
						message: this.$t('sells.campaign.rules-desc'),
						trigger: 'blur'
					}],
					name: [{
							required: true,
							message: this.$t('sells.campaign.rules-name'),
							trigger: 'blur'
						},
						{
							min: 5,
							message: this.$t('sells.campaign.rules-name-2'),
							trigger: 'blur'
						}
					],
					discountType: [{
						required: true,
						message: this.$t('sells.campaign.rules-discount'),
						trigger: 'blur'
					}],
					value: [{
							required: true,
							message: this.$t('sells.campaign.rules-value'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.campaign.rules-value-2'),
							trigger: 'blur'
						}
					],
					minOrder: [{
							required: true,
							message: this.$t('sells.campaign.rules-order'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.campaign.rules-order-2'),
							trigger: 'blur'
						}
					],
					totalUtilize: [{
							required: true,
							message: this.$t('sells.campaign.rules-total'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.campaign.rules-total-2'),
							trigger: 'blur'
						}
					],
					userUtilize: [{
							required: true,
							message: this.$t('sells.campaign.rules-user'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.campaign.rules-user-2'),
							trigger: 'blur'
						}
					],
					dateStart: [{
						required: true,
						message: this.$t('sells.campaign.rules-date-start'),
						trigger: 'blur'

					}],
					dateEnd: [{
						required: true,
						message: this.$t('sells.campaign.rules-date-end'),
						trigger: 'blur'

					}],
					domainRestriction: [{
						required: false,
						message: this.$t('sells.campaign.rules-domain'),
						trigger: 'blur'

					}],
					productRestrictions: [{
						required: false
					}]
				}
			};
		},
		methods: {
			generateCoupons() {
				if (this.couponsToGenerate < 1) return false;

				this.$confirm(
						this.$t('sells.campaign.confirm-generate') + ' ' + this.couponsToGenerate + (this
							.couponsToGenerate < 2 ? ' ' + this.$t('sells.campaign.coupon') : ' ' + this.$t(
								'sells.campaign.coupons')) +
						' ?',
						this.$t('general.warning'), {
							confirmButtonText: this.$t('general.yes'),
							cancelButtonText: this.$t('general.no'),
							type: 'warning'
						}
					)
					.then(() => {
						generateCoupons({
							campaignId: this.campaignId,
							couponsNumber: this.couponsToGenerate
						}).then((res) => {
							if (has(res, 'message') && has(res.message, 'success') && parseInt(res.message
									.success) === 1) {
								this.getCouponsCampaignData();
							} else {
								this.sbMsg({
									type: 'warn',
									message: this.$t('sells.campaign.generate-error')
								});
								return false;
							}
						});
						return true;
					})
					.catch(() => {
						return false;
					});
			},
			deleteCustomExpire() {
				this.$confirm(
						this.$t('sells.campaign.confirm-delete-custom-expire'),
						this.$t('general.warning'), {
							confirmButtonText: this.$t('general.yes'),
							cancelButtonText: this.$t('general.no'),
							type: 'warning'
						}
					)
					.then(() => {
						deleteCustomExpire({
							couponId: this.customExpire.couponId,
							campaignId: this.campaignId
						}).then((res) => {
							if (has(res, 'message') && has(res.message, 'success')) {
								this.resetCustomExpireModal();
								this.getCouponsCampaignData();
							}
						});
						return true;
					})
					.catch(() => {
						return false;
					});
			},
			saveCustomExpire() {
				saveCustomExpire({
					...this.customExpire,
					...{
						campaignId: this.campaignId
					}
				}).then((res) => {
					if (has(res, 'message') && has(res.message, 'success')) {
						this.resetCustomExpireModal();
						this.getCouponsCampaignData();
					}
				});
			},
			resetCustomExpireModal() {
				this.customExpire = {
					modal: false,
					date: '',
					couponId: 0,
					couponCode: ''
				};
			},
			deleteCustomAmount() {
				this.$confirm(
						this.$t('sells.campaign.confirm-delete-custom-amount'),
						this.$t('general.warning'), {
							confirmButtonText: this.$t('general.yes'),
							cancelButtonText: this.$t('general.no'),
							type: 'warning'
						}
					)
					.then(() => {
						deleteCustomAmount({
							couponId: this.customAmount.couponId,
							campaignId: this.campaignId
						}).then((res) => {
							if (has(res, 'message') && has(res.message, 'success')) {
								this.resetCustomAmountModal();
								this.getCouponsCampaignData();
							}
						});
						return true;
					})
					.catch(() => {
						return false;
					});
			},
			saveCustomAmount() {
				saveCustomAmount({
					...this.customAmount,
					...{
						campaignId: this.campaignId
					}
				}).then((res) => {
					if (has(res, 'message') && has(res.message, 'success')) {
						this.resetCustomAmountModal();
						this.getCouponsCampaignData();
					}
				});
				return true;
			},
			resetCustomAmountModal() {
				this.customAmount = {
					modal: false,
					amount: '',
					couponId: 0,
					couponCode: ''
				};
			},
			addRestriction(type) {
				switch (type) {
					case 'category':
						this.restrictions[type].push({
							restrict_id: 0,
							category_id: [],
							coupon_restrict: 'N'
						});
						break;

					case 'manufacturer':
						this.restrictions[type].push({
							restrict_id: 0,
							manufacturers_id: '',
							coupon_restrict: 'N'
						});
						break;

					case 'product':
						this.restrictions[type].push({
							restrict_id: 0,
							products_model: '',
							coupon_restrict: 'N'
						});
						break;
				}
			},
			saveCampaign() {
				this.$refs['campaign'].validate((valid) => {
					if (valid) {
						const params = Object.assign({
							campaignId: this.campaignId
						}, this.campaign);
						updateCouponsCampaign(params).then((res) => {
							if (typeof res.message.status !== 'undefined' && parseInt(res.message
									.status) === 1) {
								this.sbMsg({
									type: 'success',
									message: this.$t('sells.campaign.save-campaign-success')
								});
								this.getCouponsCampaignData();
							} else {
								this.sbMsg({
									type: 'warn',
									message: this.$t('sells.campaign.save-campaign-error')
								});
							}
						});
					} else {
						return false;
					}
				});
			},
			getCurrentCategory(categ) {
				const newData = Object.keys(Object.keys(categ).reduce(function(obj, key) {
					obj[categ[key]] = key;
					return obj;
				}, {}))[0];

				let c = [];

				function recursiveFindValue(tree, label, parents) {
					if (tree.label === label) {
						return {
							found: true,
							parents: union(parents, [tree.value])
						};
					}

					let result = {
						found: false
					};
					if (typeof tree.children !== 'undefined') {
						forEach(tree.children, (subtree, index) => {
							const maybeParents = union(parents, []);
							if (typeof tree.value !== 'undefined') {
								maybeParents.push(tree.value);
							}
							const maybeResult = recursiveFindValue(subtree, label, maybeParents);
							if (maybeResult.found) {
								result = maybeResult;
								return false;
							}
						});
					}
					return result;
				}
				for (const i in this.categories) {
					c = recursiveFindValue(this.categories[i], newData, []);
					if (c.found) {
						return c.parents;
					}
				}
				return c;
			},
			getCouponsCampaignData() {
				getCouponsCampaignData({
					campaignId: this.campaignId
				}).then((res) => {
					if (typeof res.message !== 'undefined' && Object.keys(res.message).length > 0) {
						this.campaign = res.message.details;
						if (typeof res.message.restrictions !== 'undefined') {
							if (has(res.message.restrictions, 'category')) {
								for (const i in res.message.restrictions.category) {
									const catParsed = this.getCurrentCategory(res.message.restrictions.category[i]
										.categs);
									if (typeof catParsed.found !== 'undefined' && catParsed.found === false) {
										this.sbMsg({
											type: 'warn',
											message: this.$t('sells.campaign.error-get-campaign'),
										});
										return false;
									}
									res.message.restrictions.category[i].category_id = catParsed;
								}
								this.restrictions.category = res.message.restrictions.category;
							}
							if (has(res.message.restrictions, 'manufacturer')) {
								this.restrictions.manufacturer = res.message.restrictions.manufacturer;
							}
							if (has(res.message.restrictions, 'product')) {
								this.restrictions.product = res.message.restrictions.product;
							}
							if (has(this.campaign, 'active')) {
								this.campaign.active = !!this.campaign.active;
							}

							if (has(res.message, 'coupons')) {
								this.coupons = res.message.coupons;
							}
						}
					}
				});
			},
			getSupplierCategsBrandsAndProducts() {
				getSupplierCategsBrandsAndProducts().then((res) => {
					if (typeof res.message === 'undefined') return false;
					if (has(res.message, 'categs')) {
						this.parseCategories(res.message.categs.categories);
					}
					if (has(res.message, 'mans')) {
						this.manufacturers = res.message.mans;
					}
					if (has(res.message, 'products')) {
						this.products = res.message.products.data;
					}
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getSupplierCategsBrandsAndProducts'
						}
					});
					debugPromise('getCategories', e);
				});
			},
			parseCategories(categories) {
				this.categories = parseCategories(categories);
				EventBus.$emit('doneCategs', true);
			},
			discountTypeDisplay(type) {
				return this.discountType.filter((item) => {
					return item.value === type;
				})[0].label;
			},
			statusDisplay(status) {
				return status === 'Y' ? this.$t('sells.campaign.active') : this.$t('sells.campaign.inactive');
			},
			deleteRestriction(type, index) {
				this.$confirm(
						this.$t('sells.campaign.confirm-delete-restriction'),
						this.$t('general.warning'), {
							confirmButtonText: this.$t('general.yes'),
							cancelButtonText: this.$t('general.no'),
							type: 'warning'
						}
					)
					.then(() => {
						if (parseInt(this.restrictions[type][index].restrict_id) > 0) {
							deleteCampaignRestriction({
								type,
								campaignId: this.campaignId,
								restrictId: this.restrictions[type][index].restrict_id
							}).then((res) => {
								if (has(res, 'message') && has(res.message, 'success')) {
									this.restrictions[type].splice(index, 1);
								}
							});
						} else {
							this.restrictions[type].splice(index, 1);
						}
						return true;
					})
					.catch(() => {
						return false;
					});
			},
			saveRestriction(type) {
				if (this.restrictions[type].length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('sells.campaign.no-restrictions') + ' ' + type,
					});
					return false;
				}
				const params = {
					campaignId: this.campaignId,
					restrictions: [],
					type
				};
				if (type === 'category') {
					for (const i in this.restrictions[type]) {
						params.restrictions.push({
							category_id: this.restrictions[type][i].category_id.slice(-1)[0],
							coupon_restrict: this.restrictions[type][i].coupon_restrict,
							restrict_id: this.restrictions[type][i].restrict_id
						});
					}
				} else {
					params.restrictions = this.restrictions[type];
				}

				updateCampaignRestrictions(params).then((res) => {
					if (has(res, 'message') && has(res.message, 'success')) {
						this.getCouponsCampaignData();
						return true;
					}
				});
			},
			checkCustomClick(row, column, cell, event) {
				switch (column.property) {
					case 'custom_amount':
						this.openCustomAmountDialog(row);
						break;

					case 'custom_expire':
						this.openCustomExpireDialog(row);
						break;
				}
				return true;
			},
			toggleCouponStatus(row) {
				if (typeof row === 'undefined') return false;
				toggleCouponStatus({
					couponId: row.coupon_id,
					campaignId: this.campaignId,
					status: parseInt(row.status) === 1 ? 0 : 1
				}).then((res) => {
					if (has(res, 'message') && has(res.message, 'success')) {
						this.getCouponsCampaignData();
					}
				});
				return true;
			},
			openCustomAmountDialog(row) {
				this.customAmount.amount = typeof row.custom_amount !== 'undefined' && row.custom_amount != null ? row
					.custom_amount :
					'';
				this.customAmount.couponId = row.coupon_id;
				this.customAmount.couponCode = row.coupon_code;
				this.customAmount.modal = true;
			},
			openCustomExpireDialog(row) {
				this.customExpire.date = typeof row.custom_expire === 'undefined' || row.custom_expire == null || row
					.custom_expire === '0000-00-00' ? '' : row.custom_expire;
				this.customExpire.couponId = row.coupon_id;
				this.customExpire.couponCode = row.coupon_code;
				this.customExpire.modal = true;
			}
		},
		computed: {
			valueTipe() {
				const v = this.discountType.filter((item) => item.value === this.campaign.discountType);
				return v.length > 0 ? v[0].currency : 'RON';
			}
		},
		watch: {},
		mounted() {
			this.campaignId = this.cid;
			if (this.campaignId === 0 || typeof this.campaignId === 'undefined') {
				this.sbMsg({
					type: 'warn',
					message: this.$t('sells.campaign.invalid-id-campaign'),
				});
				return false;
			}
			this.getSupplierCategsBrandsAndProducts();
			EventBus.$on('doneCategs', () => {
				this.getCouponsCampaignData();
			});
		},
		beforeDestroy() {
			EventBus.$off('doneCategs');
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.campaign-add-card {
		.el-form-item {
			margin-bottom: 15px;
		}

		.sub-title {
			margin-bottom: 5px;
		}

		.el-form-item__content {
			padding-bottom: 12px !important;
		}

	}

	.width-80 {
		width: 63%;
		margin-bottom: 10px;
	}

	.width-17 {
		width: 18%;
	}

	.delete-btn {
		margin-left: 10px;
	}

	.restrictions-block {
		height: 280px;
		overflow-y: auto;
	}
</style>